import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { Header, Menu, Button, Label } from 'semantic-ui-react';
import { getTask, getComments, closeTaskServer } from '../apiCall';
import userDummy from '../../assets/images/user-dummy.png';
import LexxComment from "../LexxTask/LexxComment";
import LexxLogs from "../LexxReport/LexxLogs";
import SavedSessionCard from "../LexxCarousel/SavedSessionCard";
import { saveReportReviewOptions, saveCompletedReportReviewOptions } from "../../redux/actions/index";


function LexxReportSummaryReview(props) {

    const [task, setTask] = useState([]); // array to hold the task data
    const [comments, setComments] = useState([]); // array to hold the list of comments

    /* Function to get the task details and set it in task variable */
    const getTaskServerCall = () => {
        getTask("/" + props.match.params.id + "?isReport=true").then((response) => {
            setTask(response.data.data.task);
        }).catch((error) => {
            console.log(error);
        });
        getCommentsServerCall();
    }

    /* Function to get the comments for that task and set it in comments variable */
    const getCommentsServerCall = () => {
        getComments("/" + props.match.params.id).then((response) => {
            setComments(response.data.data.comments);
        }).catch((error) => {
            console.log(error);
        });
    }

    /* Function to convert UTC date to readable date */
    const getDate = (dateUTC) => {
        return dateUTC.getDate() + '/' + (dateUTC.getMonth() + 1) + '/' + dateUTC.getFullYear();
    }

    /* Function to convert UTC date to readable time */
    const getTime = (dateUTC) => {
        if (dateUTC.getMinutes().toString().length === 1) {
            return dateUTC.getHours() + ":0" + dateUTC.getMinutes();
        } else {
            return dateUTC.getHours() + ":" + dateUTC.getMinutes();
        }
    }

    /* This function is called when the user closes the report
        this can only be done by the owner of the task
    */
    const closeReport = () => {
        closeTaskServer("/" + props.match.params.id + "/closed");
    }

    /* This function gets all the sessions to be reviewed in a task
        if the sessions are more than 0, loop over each session and push it in array
        if the session id matches with the task sessions, show it to user
    */
    const sessionsReview = () => {
        var sessionsToShow = [];
        var sessionsNeeded = [];
        if (task.sessions.length > 0) {
            task.report.sessionConfigs.forEach((eachSession) => {
                sessionsNeeded.push(eachSession.sessionId);
            });
            props.saveReportReviewOptions(sessionsNeeded);
            props.saveCompletedReportReviewOptions([]);
            task.sessions.forEach((session, idx) => {
                if (sessionsNeeded.includes(session.sessionId)) {
                    sessionsToShow.push(<SavedSessionCard key={idx} sessionData={session} isClickable={false} />);
                } else {
                    return null
                }
            })
        } else {
            return sessionsToShow;
        }
        return sessionsToShow;
    }

    /* Take the user to the session review page */
    const goNext = () => {
        props.history.push("/reviewReportSession/id=" + props.match.params.id);
    }

    useEffect(getTaskServerCall, []);

    return (
        <div className="session-summary report-summary-step4">
            <div className="header-container">
                <Header as="h2">Task Report</Header>
                {
                    task.closed ? null :
                        <span className="saveDraft" onClick={() => closeReport()}>Close Report</span>
                }
                <div className="task-details">{task.taskNum !== "" ? task.taskNum + " • " + task.taskTitle : ""}</div>
                {/* <div>
                    <span className="filled"></span>
                    {
                         Object.keys(task).length && task.report.sessionConfigs.map((session, i) => {
                            return <span key={i}></span>
                        })
                    }
                </div> */}
            </div>
            {
                Object.keys(task).length > 0 ?
                    <>
                        <div className="session-details">
                            <div>
                                <p className="label">Type of Task</p>
                                <p className="value">
                                    <span className={'task-priority ' + task.taskPriority.toLowerCase()}> {task.taskPriority} </span>
                                    <span className="task-type"> {task.taskType} </span>
                                </p>
                            </div>
                            <div>
                                <p className="label">Number of Sessions</p>
                                <p className="value">{task.report.sessionConfigs.length}</p>
                            </div>
                            <div>
                                <p className="label">Assignee</p>
                                <p className="value assignee">
                                    <Label circular className="collaborators float-left" as='a' image={task.assignee.avatar === "" ? userDummy : task.assignee.avatar} />
                                    <span>{task.assignee.name}</span>
                                </p>
                            </div>
                            <div>
                                <p className="label">Date and time started</p>
                                {/* <p className="value">{getDate(new Date(sessionData.createTime)) + " " + getTime(new Date(sessionData.createTime))}</p> */}
                            </div>
                            <div>
                                <p className="label">Date and time finished</p>
                                <p className="value">{getDate(new Date(task.completeTime)) + " " + getTime(new Date(task.completeTime))}</p>
                            </div>
                        </div>
                        <Header as="h3">Sessions</Header>
                        <div className="sliding-cards">
                            {sessionsReview()}
                        </div>
                        <div>
                            <Header as="h3">Comments</Header>
                            {comments.map((comment, i) => <div key={i} className="commentsWrapper"><LexxComment comment={comment} /></div>)}
                        </div>

                        <div className="review-logs">
                            <Header as="h3">Logs</Header>
                            {task.logs !== undefined ? task.logs.map((log, i) => <div key={i} className="logsWrapper"><LexxLogs log={log} /></div>) : null}
                        </div>
                    </>
                    : null
            }
            {
                Object.keys(task).length > 0 && task.report.sessionConfigs.length === 0 ?
                    <Menu borderless fixed='bottom' id="menu">
                        <Menu.Item className="width100">
                            <Button className='primarybtn' onClick={() => closeReport()}>
                                Approve and Close Task
                            </Button>
                        </Menu.Item>
                    </Menu>
                    :
                    <Menu borderless fixed='bottom' id="menu">
                        <Menu.Item className="width100">
                            <Button className='primarybtn' onClick={() => goNext()}>
                                Next
                            </Button>
                        </Menu.Item>
                    </Menu>
            }
        </div>

    )
}

const mapStateToProps = (state) => {
    return {
        userId: state.userInfo.userId
    };
};

export default withRouter(connect(mapStateToProps, { saveReportReviewOptions, saveCompletedReportReviewOptions })(LexxReportSummaryReview));