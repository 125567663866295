import React from 'react';
import { useState } from 'react';
import { Sidebar, Menu, Icon, Image } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { changeSidebarVisibility } from '../../redux/actions';
import KeycloakUserService from '../login/KeyCloakService';


/* This is the sidebar component for the app which various items */
const SidebarComp = (props) => {
  const [groupId, setgroupId] = useState(KeycloakUserService.getGroups());
  /* Function redirects the user to the specific page based on the item clicked */
  const clickMenu = (section) => {
    props.changeSidebarVisibility(props.sidebarVisible); // save in redux if the menu is open or not
    switch (section) {
      case 'team':
        props.history.push('/myteam');
        break;
      case 'tasks':
        props.history.push('/allTasks');
        break;
      case 'sessions':
        props.history.push('/allSessions');
        break;
      case 'profile':
        props.history.push('/userProfile');
        break;
      case "newsolutions":
        props.history.push("/newsolutions");
        break;
      case 'reports':
        props.history.push('/reports');
        break;
      case 'qrcode':
        props.history.push('/qrcode');
        break;
      default:
        props.history.push('/');
        break;
    }
  }

  const renderAddsolution = () => {
    if (groupId[0] === '/EDPRPQ_dev') {
      return  (<Menu.Item as="a" onClick={() => clickMenu("newsolutions")}>
      <Icon name="plus circle" />
      Add a solution
    </Menu.Item>);
    } else {
      return (<Menu.Item as='a' onClick={null} className='disable-color'>
      <Icon name='plus circle' />
      Add a solution
    </Menu.Item>);
    }
  }

  return (

      <Sidebar
        as={Menu}
        animation='overlay'
        icon='labeled'
        inverted
        onHide={() => {
          props.changeSidebarVisibility(true);  // It's important to use the action from props
        }}
        vertical
        visible={props.sidebarVisible}
        width='thin'
      >
        <Menu.Item as='a' onClick={() => clickMenu('dashboard')} >
          <Icon name='home' />
          Dashboard
        </Menu.Item>
        <Menu.Item as='a' onClick={() => clickMenu('team')}>
          <Icon name='users' />
          My Team
        </Menu.Item>
        <Menu.Item as='a' onClick={null} className='disable-color'>
          <Icon name='calendar alternate outline' />
          My Schedule
        </Menu.Item>
        <Menu.Item as='a' onClick={() => clickMenu('tasks')}>
          <Icon name='tasks' />
          My Tasks
        </Menu.Item>
        <Menu.Item as='a' onClick={() => clickMenu('sessions')}>
          <Icon name='chat' />
          My Sessions
        </Menu.Item>
        <Menu.Item as='a' onClick={() => clickMenu('reports')}>
          <Icon name='wrench' />
          My Reports
        </Menu.Item>
        <Menu.Item as='a' onClick={null} className='disable-color'>
          <Icon name='wrench' />
          Tools
        </Menu.Item>
        <Menu.Item as='a' onClick={() => clickMenu('profile')} >
          <Icon name='user outline' />
          My Profile
        </Menu.Item>
        <Menu.Item as='a' onClick={() => clickMenu('qrcode')} className='disable-color'>
          <Icon name='qrcode' />
          QR Scanner
        </Menu.Item>
        <Menu.Item as='a' onClick={null} className='disable-color'>
          <Icon name='setting' />
          Settings
        </Menu.Item>
        {/* <Menu.Item as="a" onClick={() => clickMenu("newsolutions")}>
          <Icon name="plus circle" />
          Add a solution
        </Menu.Item> */}
        {renderAddsolution()}

        <div className="energyLogo-Wrapper">
          <Image src={require("../../assets/images/lexx-energy-logo.png")} />
        </div>
      </Sidebar>
  );
};

const mapStateToProps = (state) => {
  return {
    sidebarVisible: state.sidebarVisible
  }
}

export default withRouter(connect(mapStateToProps, {
  changeSidebarVisibility,
})(SidebarComp));