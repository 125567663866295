import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { Header, Label } from 'semantic-ui-react';
import { getReports } from '../apiCall';

/* Component displays all the reports for a user */
function LexxReports(props) {

    const [reports, setReports] = useState(""); // array to hold the reports

    /* Extract all the reports from server 
    and set them in reports variable */
    const getReportsFromServer = () => {
        getReports().then((response) => {
            setReports(response.data.data.tasks);
        }).catch((error) => {
            console.log(error);
        });
    }

    useEffect(getReportsFromServer, []);

    /* Redirect the user to task page where report is stored */
    const reportClicked = (taskId) => {
        props.history.push(`/viewTask/id=${taskId}`);
    }

    return (
        <div className="my-sessions">
            <Header as="h2">My Reports</Header>
            {reports.length > 0 && reports.map((item, key) => {
                return (
                    <div key={key} className="each-session taskList" onClick={() => reportClicked(item.taskId)}>
                        <div className="each-task">
                            <div className="task-info">
                                <div className="time"> {item.taskNum} </div>
                                <div className="query"> {item.taskTitle} </div>
                            </div>
                            <Label circular className="collaborators float-right" as='a' image={item.assignee.avatar} />
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        userId: state.userInfo.userId
    };
};

export default withRouter(connect(mapStateToProps, null)(LexxReports));