import { combineReducers } from "redux";

const showSidebarReducer = (visible = false, action) => {
  if (action.type === "SIDEBAR_VISIBLE") {
    return action.payload;
  }
  return visible;
};

const searchResultsReducer = (results = null, action) => {
  if (action.type === "SEARCH_RESULTS_SAVED") {
    return action.payload;
  }
  return results;
};

const troubleshootingDataReducer = (data = null, action) => {
  if (action.type === "TROUBLESHOOTING_DATA_SAVED") {
    return action.payload;
  }
  return data;
};

const troubleshootingOptionReducer = (option = null, action) => {
  switch (action.type) {
    case "TROUBLESHOOTING_OPTION_SAVED":
      return action.payload;
    case "TROUBLESHOOTING_OPTION_CLEARED":
      return null;
    default:
      return option;
  }
};

const troubleshootingStepReducer = (step = null, action) => {
  if (action.type === "TROUBLESHOOTING_STEP_SAVED") {
    return action.payload;
  }
  return step;
};

const visitedTroubleshootingStepsReducer = (steps = [], action) => {
  if (action.type === "VISITED_TROUBLESHOOTING_STEPS_SAVED") {
    return action.payload;
  }
  return steps;
};

const searchTypeReducer = (isTroubleshooting = true, action) => {
  if (action.type === "SET_SEARCH_TYPE") {
    return action.payload;
  }
  return isTroubleshooting;
};

const getTaskIdReducer = (taskId = null, action) => {
  if (action.type === "TASK_ID") {
    return action.payload;
  }
  return taskId;
};

// const savedSessionsReducer = (savedSessions = [], action) => {
//     switch (action.type) {
//         case 'SESSION_SAVED':
//             return [...savedSessions, action.payload];
//         case 'SESSIONS_CLEARED':
//             return [];
//         default:
//             return savedSessions;
//     }
// };

const searchQueryReducer = (query = null, action) => {
  switch (action.type) {
    case "QUERY_SAVED":
      return action.payload;
    default:
      return query;
  }
};

const getSessionID = (sessionData = null, action) => {
  switch (action.type) {
    case "SESSION_ID":
      return {
        ...sessionData,
        sessionID: action.payload.sessionID,
        isOwner: action.payload.isOwner,
        isShared: action.payload.isShared,
      };

    default:
      return sessionData;
  }
};

const getSessionUsers = (sessionUsers = null, action) => {
  switch (action.type) {
    case "SESSION_USERS":
      return action.payload;
    default:
      return sessionUsers;
  }
};

const loginReducer = (userInfo = null, action) => {
  if (action.type === "LOGIN") {
    return {
      ...userInfo,
      username: action.payload.split("@")[0],
      userId: action.payload,
      authenticated: true,
      signupProcess: false,
      resetPasssword: false,
    };
  }
  return userInfo;
};

const searchClickedReducer = (isSearch = false, action) => {
  if (action.type === "STOP_SESSION_CREATE") {
    return action.payload;
  }
  return isSearch;
};

const profileCompletedReducer = (isCompleted = false, action) => {
  if (action.type === "PROFILE_COMPLETED") {
    return action.payload;
  }
  return isCompleted;
};

const isPositiveFeedbackReducer = (isPositive = false, action) => {
  if (action.type === "FEEDBACK") {
    return action.payload;
  }
  return isPositive;
};

const activeTimer = (time = null, action) => {
  if (action.type === "ACTIVE_TIME") {
    return action.payload;
  }
  return time;
};

const sessionOptionsForReport = (options = null, action) => {
  if (action.type === "SAVE_SESSION_OPTIONS") {
    return action.payload;
  }
  return options;
};

export const saveCompletedSessionOptions = (options = [], action) => {
  if (action.type === "SAVE_COMPLETED_SESSION_OPTIONS") {
    return action.payload;
  }
  return options;
};

export const saveDraftReport = (report = {}, action) => {
  if (action.type === "SAVE_DRAFT_REPORT") {
    return action.payload;
  }
  return report;
};

export const saveReportReviewOptions = (options = [], action) => {
  if (action.type === "SAVE_REPORT_REVIEW_OPTIONS") {
    return action.payload;
  }
  return options;
};

export const saveCompletedReportReviewOptions = (options = [], action) => {
  if (action.type === "SAVE_COMPLETED_REPORT_REVIEW_OPTIONS") {
    return action.payload;
  }
  return options;
};

export const saveProfileUsers = (users = [], action) => {
  if (action.type === "SAVE_USERS") {
    return action.payload;
  }
  return users;
};

const getGeoLocationReducer = (loc = { lat: "", lng: "" }, action) => {
  if (action.type === "LOCATION") {
    return action.payload;
  }
  return loc;
};

const solutionReducer = (solutionInfo = null, action) => {
  if(action.type === "ADD_SOLUTION_FAULT") {
    return {
      ...solutionInfo,
      title: action.payload.title,
      code: action.payload.code,
      description: action.payload.description,
      solutionId: action.payload.solutionId ? action.payload.solutionId : "",
    };
  } else if( action.type === "RESET_SOLUTION_FAULT") {
    return (solutionInfo = null);
  }
  return solutionInfo;
};

const stepsReducer = (stepsInfo = [], action) => {
  if(action.type === "ADD_STEP"){
    stepsInfo.push(action.payload);
    return stepsInfo;
  } else if ( action.type === "REMOVE_STEP") {
    return stepsInfo.filter((data) => data.title !== action.payload);
  } else if ( action.type === "RESET_STEPS") {
    return (stepsInfo = []);
  } else if ( action.type === "UPDATE_STEP") {
    stepsInfo.map((stepDetails) => {
      if ((stepDetails.stepId.length > 0 && stepDetails.stepId === action.payload.stepId)
    || (stepDetails.title === action.payload.title)) {
        stepDetails.title = action.payload.title;
        stepDetails.description = action.payload.description;
        stepDetails.tools = action.payload.tools;
        stepDetails.spares = action.payload.spares;
        stepDetails.documents = action.payload.documents;
        stepDetails.order_id = action.payload.order_id;
        stepDetails.uploadedMedia = action.payload.uploadedMedia;
        stepDetails.capturedMedia = action.payload.capturedMedia;
        stepDetails.deleteFileCollection =
          action.payload.deleteFileCollection;
        stepDetails.media = action.payload.media;
      }
      return stepDetails;
    });
    return stepsInfo;
  } else if (action.type === "UPDATE_STEPID") {
    // eslint-disable-next-line array-callback-return
    stepsInfo.map((stepDetails) =>{
      if(stepDetails.order_id === action.payload.order_id) {
        stepDetails.stepId = action.payload.stepId;
      }
      return stepDetails
    });
    return stepsInfo;
  }
  return stepsInfo;
};

const stepCollectionReducer = (stepsCollection = [], action) => {
  if (action.type === "ADD_STEP_COLLECTION") {
    stepsCollection.push(action.payload);
    return stepsCollection;
  }
  if (action.type === "RESET_STEPS") {
    return (stepsCollection = []);
  }
  return stepsCollection;
};

const deleteStepReducer = (deleteStepsCollection = [], action) => {
  if (action.type === "DELETE_STEP") {
    deleteStepsCollection.push(action.payload);
    return deleteStepsCollection;
  } else if(action.type === "RESET_STEPS") {
    return deleteStepsCollection = [];
  }
  return deleteStepsCollection;
};

const editStepReducer = (editStep = [], action) => {
  if(action.type === "EDIT_STEP") {
    editStep.push(action.payload);
  } else if(action.type === "RESET_EDIT_STEP") {
    return (editStep = []);
  }
  return editStep;
};

const editStepIdsReducer = (editStepIds = [], action) => {
  if (action.type === "SAVE_STEP_IDS") {
    editStepIds.push(action.payload);
  }
  if (action.type === "RESET_STEPS") {
    return (editStepIds = []);
  }
  if (action.type === "UPDATE_STEP") {
    editStepIds.push(action.payload.stepId);
  }
  if (action.type === "RESET_EDIT_STEPIDS") {
    return (editStepIds = []);
  }
  return editStepIds;
};

const saveStepsOrder = (stepsOrder = [], action) => {
  if (action.type === "SAVE_STEPS_INDEX") {
    stepsOrder.push(action.payload);
  }
  if (action.type === "RESET_STEPS") {
    return (stepsOrder = []);
  }
  return stepsOrder;
};

const solutionNotSavedReducer = (isSolutionNotSaved = false, action) => {
  if (action.type === "SOLUTION_NOT_SAVED") {
    return isSolutionNotSaved = action.payload;
  }
  return isSolutionNotSaved;
};

const setFeatureFlagReducer = (featureFlag = [], action) => {
  if(action.type === "SET_FEATURE_FLAG") {
    featureFlag = action.payload;
  }
  return featureFlag;
};

const savefileLinkReducer = (fileLink = "", action) => {
  if (action.type === "STORE_FILELINK") {
    fileLink = action.payload;
  }

  return fileLink;
};
const saveOutlineReducer = (outline = "", action) => {
  if (action.type === "STORE_OUTLINE") {
    outline = action.payload;
  }

  return outline;
};
const saveDocDetailReducer = (docDetail = {}, action) => {
  if (action.type === "STORE_DOC_DETAIL") {
    docDetail = action.payload;
  }

  return docDetail;
};

const setLanguageSelected = (languageselected ="",action)=>{
  if(action.type === "SET_LANGUAGE"){
     languageselected = action.payload
  }
  return languageselected
}

const textfordashboard =(displaytextcontent="",action)=>{
  if(action.type === "SET_DISPLAY_TEXTS"){
    displaytextcontent = action.payload
  }
  return displaytextcontent
}

const collectionnamesearch = (collectionname="",action)=>{
  if(action.type === "SET_COLLECTION_CHANGE"){
    collectionname = action.payload;
  }
  return collectionname
}

const activePageReducer = (activePage = 1, action) => {
  if (action.type === "SET_ACTIVE_PAGE") {
    activePage = action.payload;
  }

  return activePage;
};

const totalDocReducer = (totalDoc = 0, action) => {
  if (action.type === "SET_TOTAL_DOC") {
    totalDoc = action.payload;
  }

  return totalDoc;
};

const filtervalueReducer = (filtervalues = "", action) => {
  if (action.type === "SET_FILTER_VALUES") {
    filtervalues = action.payload;
  }

  return filtervalues;
};

const addingnewFilter = (filter = "", action) => {
  if (action.type === "ADD_FILTER") {
    filter = action.payload;
  }
  return filter;
};

export default combineReducers({
  sidebarVisible: showSidebarReducer, // not part of session data
  // savedSessions: savedSessionsReducer, // not part of session data
  searchResults: searchResultsReducer,
  troubleshootingData: troubleshootingDataReducer,
  troubleshootingOption: troubleshootingOptionReducer,
  troubleshootingStep: troubleshootingStepReducer,
  visitedTroubleshootingSteps: visitedTroubleshootingStepsReducer,
  isTroubleshooting: searchTypeReducer,
  searchQuery: searchQueryReducer,
  userInfo: loginReducer,
  feedback: isPositiveFeedbackReducer,
  timer: activeTimer,
  isCompleted: profileCompletedReducer,
  sessionData: getSessionID,
  taskId: getTaskIdReducer,
  isSearchClicked: searchClickedReducer,
  sessionUsers: getSessionUsers,
  savedSessionOptions: sessionOptionsForReport,
  completedSessionOptions: saveCompletedSessionOptions,
  savedReportReviewOptions: saveReportReviewOptions,
  completedReportReviewOptions: saveCompletedReportReviewOptions,
  draftReport: saveDraftReport,
  saveProfileUsers: saveProfileUsers,
  geolocation: getGeoLocationReducer,
  solution: solutionReducer,
  steps: stepsReducer,
  stepsCollection: stepCollectionReducer,
  deleteStepsCollection: deleteStepReducer,
  editStep: editStepReducer,
  editStepIds: editStepIdsReducer,
  stepsOrder: saveStepsOrder,
  isSolutionNotSaved: solutionNotSavedReducer,
  featureFlag: setFeatureFlagReducer,
  languageselected:setLanguageSelected,
  displaytextcontent:textfordashboard,
  collectionname:collectionnamesearch,
  fileLinkRedux: savefileLinkReducer,
  outLineRedux: saveOutlineReducer,
  docDetail: saveDocDetailReducer,
  totalDoc: totalDocReducer,
  activePage: activePageReducer,
  newFilter: addingnewFilter,
  newFilterValues:filtervalueReducer
});
