import React from 'react';
import { Image } from "semantic-ui-react";
import userDummy from '../../assets/images/user-dummy.png';

/* This component will help display each message of chat */
const LexxMessage = ({ message: { data, sourceId, isFile, createTime }, username, usersInChat }) => {
    let isSentByCurrentUser = false; // by default logged in user's message is false
    let videoFormats = [".mov", ".mp4", ".wmv", ".avi"]; // videoFormats allowed
    const trimmedName = username.trim().toLowerCase();

    // if the source of the message and logged in user is same
    if (sourceId === trimmedName) {
        isSentByCurrentUser = true;
    }

    /* Function to get the current date
      "today" - if the message sent today
      "dd/mm/yyyy" - if any other
    */ 
    const getDate = (dateUTC) => {
        let date = new Date();
        let dateMessage = dateUTC.getDate() + '/' + (dateUTC.getMonth() + 1) + '/' + dateUTC.getFullYear();
        let todayDate = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
        return dateMessage === todayDate ? "Today" : dateMessage;
    }

    /* Function to get the time */
    const getTime = (dateUTC) => {
        if (dateUTC.getMinutes().toString().length === 1) {
            return dateUTC.getHours() + ":0" + dateUTC.getMinutes();
        } else {
            return dateUTC.getHours() + ":" + dateUTC.getMinutes();
        }
    }

    /* Get the profile image of the user based on the parameter
        @name - name of the user whose profile image is to be shown
        usersInChat is an array with all the users and profile image of them
    */
    const getProfileImage = (name) => {
        for (var eachUser in usersInChat) {
            if (eachUser === name) {
                return usersInChat[eachUser];
            }
        }
    }

    return (
        isSentByCurrentUser
            ? (
                <div className="messageContainer justifyEnd">
                    <div className="left-align">
                        {isFile ?
                            <div className="messageBox">
                                {videoFormats.some(eachFormat => data.toLowerCase().includes(eachFormat)) ?
                                    <video src={data} width="200" height="200" controls />
                                    :
                                    <a href={data}>
                                        <img src={data} alt="" className="sharedImg" />
                                    </a>
                                }
                            </div>
                            :
                            <div className="messageBox messageLeft backgroundBlue">
                                <p className="messageText colorWhite">{data}</p>
                            </div>
                        }
                        <div className="date">{getDate(new Date(createTime)) + " " + getTime(new Date(createTime))}</div>
                    </div>
                    <Image src={getProfileImage(sourceId).profileImg !== "" ? getProfileImage(sourceId).profileImg : userDummy} className="chatIcon" />
                </div>
            )
            : (
                <div className="messageContainer justifyStart">
                    <Image src={getProfileImage(sourceId).profileImg !== "" ? getProfileImage(sourceId).profileImg : userDummy} className="chatIcon" />
                    <div className="messageWhite">
                        {isFile ?
                            <div className="messageBox right-align">
                                {videoFormats.some(eachFormat => data.toLowerCase().includes(eachFormat)) ?
                                    <video width="200" height="200" src={data} controls />
                                    :
                                    <a href={data}>
                                        <img src={data} alt="" className="sharedImg" />
                                    </a>
                                }
                            </div>
                            :
                            <div className="messageBox backgroundLight right-align">
                                <p className="nameChat">{getProfileImage(sourceId).name}</p>
                                <p className="messageText colorDark">{data}</p>
                            </div>
                    }
                    <div className="date">{getDate(new Date(createTime)) + " " + getTime(new Date(createTime))}</div>
                     </div>


                </div>
            )
    );
}

export default LexxMessage;