import React, { useState, useEffect } from "react";
import { Container, Card, Button, Pagination } from "semantic-ui-react";
import { connect } from "react-redux";
import {
  saveSearchResults,
  saveTroubleshootingData,
} from "../../redux/actions";
import LexxBreadcrumbs from "../LexxBreadcrumbs/LexxBreadcrumbs";
import axios from "axios";
import LexxIconHeading from "../global/LexxIconHeading";
import Loader from "../global/Loader";
import {
  saveSessionData,
  searchClicked,
  saveTaskId,
  storeOutline,
  storeDocDetail,
  storeFileLink,
  setActivePage,
  setTotalDoc,
  // saveNumFound,
} from "../../redux/actions/index";
import { sessionCreate } from "../Helper";
import { getSearchResultData, search } from "../apiCall";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import languages from "../../../languagebasedtext.json";
import KeycloakUserService from "../login/KeyCloakService";

/* The component is to show the search Results */
const LexxSearchResults = (props) => {
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(10);
  console.log(props.searchResults);
  const history = useHistory();
  const perPage = 10;
  const results = 10;
  const [displaycontent, setdisplaycontent] = useState(
    props.displaytextcontent
      ? JSON.parse(props.displaytextcontent)
      : languages["english"]
  );
  const noResult = `${
    displaycontent.yousearch ? displaycontent.yousearch : "Your search"
  } ${props.searchQuery} ${
    displaycontent.noresult
      ? displaycontent.noresult
      : "did not return any results."
  }`; // when there are no search results

  const [isloading, setIsLoading] = useState(false);

  /* Function to create the session after search results */
  const sendSessionData = () => {
    if (props.isSearchClicked) {
      sessionCreate(props);
      props.searchClicked(false);
    }
  };

  function pageChange(e, data) {
    window.scrollTo(0, 0);
    setPage(data.activePage);
    triggerSearch(data.activePage);
  }

  const triggerSearch = (page) => {
    setIsLoading(true);
    axios.defaults.params = {};
    axios.defaults.params["username"] = props.userInfo.username;
    let start = rows * page - rows;
    search(props.searchQuery, props.isTroubleshooting, start, rows)
      .then((res) => {
        //sent to the backend
        // console.log(res);
        // Clear docs with no data for lonestar only
        if (localStorage.getItem("projectGroup") === "/EDPRL" || localStorage.getItem("projectGroup") === "/EDPRL_dev") {
          for (let i = 0; i < res.data.docs.length; i++) {
            if (JSON.parse(res.data.docs[i].data[0]).length == 0) {
              delete res.data.docs[i];
            }
          }
        }
        // Save and reset redux
        props.saveSearchResults(res.data.docs);
        setIsLoading(false);
        // props.history.push("/troubleshooting/issues");
      })
      .catch((err) => {
        setIsLoading(false);
        console.error(err);
        if (err.response.status === 403) {
          KeycloakUserService.doLogout();
        } 
      });
  };

  const toViewDoc = (
    id,
    rootId,
    title,
    file_link,
    outline,
    type,
    fileName,
    meta
  ) => {
    props.storeOutline(JSON.stringify(outline));
    props.storeFileLink(file_link);
    props.storeDocDetail({
      id: id,
      rootId: rootId,
      title: title,
      file_link: file_link,
      type: type,
      fileName: fileName,
      meta: meta,
    });
    history.push("/newprocedure");
  };
  useEffect(sendSessionData, []);

  /* mapping function from solr to UI for info type */
  const info_type_mapping = {
    "Examinations, tests & checks": {
      route: "procedure",
    },
    Description: {
      route: "procedure",
    },
    descript: {
      route: "procedure",
    },
    WI_TBM: {
      route: "procedure",
    },
    proced: {
      route: "procedure",
    },
    "Work Order": {
      route: "workorder",
    },
    "Fault isolation": {
      route: "faultisolation",
    },
    Schematics: {
      route: "partsbreakdown",
    },
  };

  /* Function to direct the user to the correct page based on info_type
      @payload - each result data
      get the entire result data for each result when clicked from server
  */
  const directToResultPage = (payload) => {
    console.log('payloadpayload', payload);
    axios.defaults.params = {};
    axios.defaults.params["username"] = props.userInfo.username;
    const data = {
      params: { id: payload["id"], language: props.collectionname },
    };
    getSearchResultData(`/${payload["info_type"]}`, data)
      .then((response) => {
        props.saveTroubleshootingData(response.data);
        if (payload["info_type"] === "workorder") {
          props.history.push(`/troubleshooting/issues/workorder/`);
        } else if (
          payload["info_type"] === "procedure" ||
          payload["info_type"] === "proced" ||
          payload["info_type"] === "description"
        ) {
          props.history.push(`/procedure/id=${payload["id"]}`);
        } else if (payload["info_type"] === "partsbreakdown") {
          props.history.push(`/schematic/id=${payload["id"]}`);
        } else {
          props.history.push(`/troubleshooting/issues/options/`);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  let getFirstTwoSentence = (data) => {
    let sentences = data.split(". ");
    let returnSummary = "";
    do {
      returnSummary += sentences[0] += ". ";
      sentences.shift();
    } while (returnSummary.length < 500 && sentences.length);
    if (returnSummary.length > 500) {
      let words = returnSummary.split(/\s+/);
      returnSummary = words.slice(0, 70).join(" ");
      returnSummary += "...";
    }
    return returnSummary;
  };
  if (isloading) {
    return (
        <Loader />
    )
  }else{
  return (
    <>
        <div className="content-container home-container">
          {console.log("total doc", props.totalDoc)}
          <LexxBreadcrumbs />
          {props.searchResults === null ? null : props.searchResults.length ===
            0 ? (
            <Container className="search-results-page">
              <LexxIconHeading text={noResult} />
            </Container>
          ) : (
            <Container className="search-results-page">
              <LexxIconHeading
                text={`Excellent, you inquired about " ${props.searchQuery}". Here's the information we discovered!`}
              />
              <div className="search-results-container marginL3">
                {props.searchResults?.map((result, i) => {
                  return (
                    <div key={result.id} className="lookupinfo-result">
                      <Card fluid>
                        {/* <div
                          className={
                            (props.activePage - 1) * perPage + i + 1 > 99
                              ? "count-doc-more"
                              : "count-doc"
                          }
                        >
                          {(props.activePage - 1) * perPage + i + 1}
                        </div> */}
                        <div className="heading-lookupinfo">
                          {result.root.docs[0].title
                            ? result.root.docs[0].title
                            : result.root.docs[0].file_name}
                        </div>
                        <div className="fileandtype_wrapper">
                          <div>
                            <fieldset className="type_doc">
                              <legend className="textin_label">
                                <b>TYPE</b>
                              </legend>
                              {result.root.docs[0].type
                                ? result.root.docs[0].type
                                : ""}
                            </fieldset>
                          </div>

                          <div>
                            <fieldset className="filename_doc">
                              <legend className="textin_label">
                                <b>FILE NAME</b>
                              </legend>
                              {result.root.docs[0].file_name
                                ? result.root.docs[0].file_name
                                : ""}
                            </fieldset>
                          </div>
                        </div>
                        {result.root.docs[0].type !== "Drawing" && (
                          <>
                            <div className="seperation-line"></div>
                            <div className="summary-lookup">
                              <h3>Summary</h3>
                              <div>
                                {getFirstTwoSentence(
                                  result.search_content
                                    ? result.search_content[0]
                                    : ""
                                )}
                              </div>
                            </div>
                          </>
                        )}
                        <Button
                          className="view-doc-button"
                          onClick={() =>
                            toViewDoc(
                              result.id,
                              result.root.docs[0].id,
                              result.root.docs[0].title,
                              result.root.docs[0].file_link,
                              result.root.docs[0].outline,
                              result.root.docs[0].type,
                              result.root.docs[0].file_name,
                              result.meta
                            )
                          }
                        >
                          {displaycontent.strtloohup
                            ? `${displaycontent.strtloohup}`
                            : `Look up Information`}{" "}
                          &#8594;
                        </Button>
                      </Card>
                    </div>
                  );
                })}
              </div>
              <div className="pagination-container">
              <Pagination
                activePage={page}
                onPageChange={(e, data) => pageChange(e, data)}
                totalPages={Math.ceil(props.numFound / rows)}
              />
            </div>
            </Container>
          )}
        </div>
      </>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    searchResults: state.searchResults,
    troubleshootingData: state.troubleshootingData,
    isTroubleshooting: state.isTroubleshooting,
    userInfo: state.userInfo,
    searchQuery: state.searchQuery,
    isSearchClicked: state.isSearchClicked,
    displaytextcontent: state.displaytextcontent,
    collectionname: state.collectionname,
    totalDoc: state.totalDoc,
    activePage: state.activePage,
    numFound: state.numFound,
  };
};

export default connect(mapStateToProps, {
  saveSearchResults,
  saveTroubleshootingData,
  saveSessionData,
  searchClicked,
  saveTaskId,
  storeDocDetail,
  storeFileLink,
  storeOutline,
  setActivePage,
  setTotalDoc,
  // saveNumFound,
})(LexxSearchResults);
