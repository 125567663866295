import React, { useState, useEffect } from 'react';
import { Container } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { saveSearchResults, saveTroubleshootingData } from '../../redux/actions';
import LexxCard from '../LexxCard/lexxcard';
import LexxButton from '../LexxButton/lexxbutton.jsx';
import LexxBreadcrumbs from '../LexxBreadcrumbs/LexxBreadcrumbs';
import axios from "axios";
import LexxIconHeading from '../global/LexxIconHeading';
import { saveSessionData, searchClicked, saveTaskId } from '../../redux/actions/index';
import { sessionCreate } from '../Helper';
import { getSearchResultData } from '../apiCall';

/* The component is to show the search Results */
const LexxSearchResults = (props) => {

    const SEARCH_RESULTS_FACTOR = 5; // number of results to show to user 
    const searchText = "Great, You searched for \"" + props.searchQuery + "\". Here's what we found!"; // when search results are more than 0.
    const noResult = "Your search \"" + props.searchQuery + "\" did not return any results."; // when there are no search results

    const [count, setCount] = useState(0); // to get more data for pagination
    const card_button_text = props.isTroubleshooting ? `Start Troubleshooting &#8594;` : `Look up Information &#8594;`

    /* Function to create the session after search results */
    const sendSessionData = () => {
        if (props.isSearchClicked) {
            sessionCreate(props);
            props.searchClicked(false)
        }
    }

    useEffect(sendSessionData, []);

    /* mapping function from solr to UI for info type */
    const info_type_mapping = {
        "Examinations, tests & checks": {
            route: "procedure"
        },
        "Description": {
            route: "procedure"
        },
        "descript": {
            route: "procedure"
        },
        "proced":{
            route: "procedure"
        },
        "Work Order": {
            route: "workorder"
        },
        "Fault isolation": {
            route: "faultisolation"
        },
        "Schematics": {
            route: "partsbreakdown"
        }
    };

    /* Function to direct the user to the correct page based on info_type
        @payload - each result data
        get the entire result data for each result when clicked from server
    */
    const directToResultPage = (payload) => {
        axios.defaults.params = {}
        axios.defaults.params['username'] = props.userInfo.username;
        const data = { params: { id: payload['id'] } };
        getSearchResultData(`/${payload['info_type']}`, data).then((response) => {
            props.saveTroubleshootingData(response.data);
            if (payload['info_type'] === 'workorder') {
                props.history.push(`/troubleshooting/issues/workorder/`);
            }
            else if (payload['info_type'] === 'procedure' || payload['info_type'] === 'proced' || payload['info_type'] === 'description') {
                props.history.push(`/procedure/id=${payload['id']}`);
            }
            else if (payload['info_type'] === 'partsbreakdown') {
                props.history.push(`/schematic/id=${payload['id']}`)
            }
            else {
                props.history.push(`/troubleshooting/issues/options/`);
            }
        })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <div className="content-container home-container">
            <LexxBreadcrumbs />
            {
                props.searchResults === null ? null :
                    props.searchResults.length === 0 ?
                        <Container className="search-results-page">
                            <LexxIconHeading text={noResult} />
                        </Container> :
                        <Container className="search-results-page">
                            <LexxIconHeading text={searchText} />
                            <div className="search-results-container marginL3">
                                {
                                    props.searchResults.map((item, i) => {
                                        if (i >= SEARCH_RESULTS_FACTOR * (1 + count)) {
                                            return null
                                        }
                                        item['payload'] = {
                                            'info_type': info_type_mapping[item['info_type']].route,
                                            'id': item.id
                                        }
                                        return (<LexxCard key={i} data={item} button={card_button_text} act={directToResultPage} showDesc={false} />
                                        )
                                    }
                                    )
                                }
                            </div>
                            {
                                SEARCH_RESULTS_FACTOR * (1 + count) < props.searchResults.length ?
                                    <LexxButton primary={false} pressed={false} disabled={false} text={"Show more options"} act={() => { setCount(count + 1) }} />
                                    :
                                    null
                            }
                        </Container>
            }
        </div>
    );

};

const mapStateToProps = (state) => {
    return {
        searchResults: state.searchResults,
        troubleshootingData: state.troubleshootingData,
        isTroubleshooting: state.isTroubleshooting,
        userInfo: state.userInfo,
        searchQuery: state.searchQuery,
        isSearchClicked: state.isSearchClicked
    };
};

export default connect(mapStateToProps, {
    saveSearchResults,
    saveTroubleshootingData,
    saveSessionData,
    searchClicked,
    saveTaskId
})(LexxSearchResults);