import React from 'react';

/* This component is used to show the logs in the report page */
const LexxLogs = (props) => {

     /* Function to convert UTC date to readable date */
    const getDate = (dateUTC) => {
        let date = new Date();
        let dateMessage = dateUTC.getDate() + '/' + (dateUTC.getMonth() + 1) + '/' + dateUTC.getFullYear();
        let todayDate = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
        return dateMessage === todayDate ? "Today" : dateMessage;
    }

     /* Function to convert UTC date to readable time */
    const getTime = (dateUTC) => {
        if (dateUTC.getMinutes().toString().length === 1) {
            return dateUTC.getHours() + ":0" + dateUTC.getMinutes();
        } else {
            return dateUTC.getHours() + ":" + dateUTC.getMinutes();
        }
    }

    /* Function used to change the sentence for UI based on type
        @log - each log file 
        types can be status change, delegation, new session
    */
    const getLogStatement = (log) => {
        switch (log.type) {
            case 'STATUS_CHANGE':
                return 'Status changed to ' + (log.toStatus === 'inprogress' ? 'In-Progress' : log.toStatus.charAt(0).toUpperCase() + log.toStatus.substring(1,));
            case 'DELEGATION':
                return 'Task deleted to ' + log.assigneeName;
            default:
                return 'New Session started'
        }
    }

    return (
        <div className="each-log">
            <span className="date">{getDate(new Date(props.log.time)) + " •  " + getTime(new Date(props.log.time))}</span>
            <span className="log">{getLogStatement(props.log)}</span>
        </div>
    )
}

export default LexxLogs;