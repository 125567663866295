import React, { useEffect, useState } from "react";
import { Container } from "semantic-ui-react";
import { connect } from "react-redux";
import {
  saveTroubleshootingOption,
  saveTroubleshootingStep,
  saveVisitedTroubleshootingSteps,
} from "../../redux/actions";
import LexxCard from "../LexxCard/lexxcard";
import LexxBreadcrumbs from "../LexxBreadcrumbs/LexxBreadcrumbs";
import LexxSessionToolbar from "../LexxSession/LexxSessionToolbar";
import LexxIconHeading from "../global/LexxIconHeading";
import { sessionUpdate } from "../Helper";
import { logData, logUserAction } from "../apiCall";

import KeycloakUserService from "../login/KeyCloakService";

/* This component is used to show each Troubleshooting Option in the Troubleshooting */
const LexxTroubleshootingOptions = (props) => {
  const [groupId, setgroupId] = useState(KeycloakUserService.getGroups());
  /* Function is called when an option is choosen 
     @payload - is the object which contains the step id (stp-1 etc) and search_item which is the troubleshooting field id
     */
  const onChooseOption = (payload) => {
    logUserAction({
      action: "continue step",
      stepId: payload["step_id"],
      searchResult: payload["search_item"],
      stepNumber: payload["step_number"],
    });
    const data = {
      params: {
        search_result: payload["search_item"],
        option: payload["step_id"],
      },
    };
    if (props.isOwner || (!props.isOwner && stepVisited(payload["step_id"]))) {
      logData(data);
      props.saveTroubleshootingOption(payload["step_id"]);
      props.saveTroubleshootingStep(payload["step_number"]);
      props.saveVisitedTroubleshootingSteps(
        props.visitedTroubleshootingSteps.concat([
          { search_item: payload["search_item"], step_id: payload["step_id"] },
        ])
      );
      props.history.push("/troubleshooting/issues/options/action");
    }
  };
  /* Function is called when an option is choosen and there is no details inside that option 
     @payload - is the object which contains the step id (stp-1 etc) and search_item which is the troubleshooting field id
     */
  const setStepVisited = (payload) => {
    logUserAction({
      action: "done step",
      stepId: payload["step_id"],
      searchResult: payload["search_item"],
      stepNumber: payload["step_number"],
    });
    const data = {
      params: {
        search_result: payload["search_item"],
        option: payload["step_id"],
      },
    };
    if (props.isOwner || (!props.isOwner && stepVisited(payload["step_id"]))) {
      logData(data);
      props.saveTroubleshootingOption(payload["step_id"]);
      props.saveTroubleshootingStep(payload["step_number"]);
      props.saveVisitedTroubleshootingSteps(
        props.visitedTroubleshootingSteps.concat([
          {
            search_item: payload["search_item"],
            step_id: payload["step_id"],
            step_number: payload["step_number"],
          },
        ])
      );
      sendSessionData();
    }
  };

  /* Function to return if the user is allowed to visit that step (in sharing session sometimes they can't)
        @stepClicked is the step_id the user clicked on
    */
  const stepVisited = (stepClicked) => {
    var isAllowed = props.visitedTroubleshootingSteps.some((eachStep) => {
      return eachStep.step_id === stepClicked;
    });
    return isAllowed;
  };

  /* Update the session whenever options are updated */
  const sendSessionData = () => {
    sessionUpdate();
  };

  useEffect(sendSessionData, []);

  const buttonValidate = (item) => {
    const data = props.troubleshootingData;
    let stepDetails = false;
    if (
      Object.keys(data.step_activities).length === 1 ||
      data.data.length === 1
    ) {
      return false;
    }
    data.data.forEach((value) => {
      if (value.action && value.id === item.next) {
        for (let i = 0; i < value.action.length; i++) {
          const tagData = value.action[i].tag_data[0];
          if (
            tagData?.tag_data?.length ||
            (!tagData?.includes("N/A") && tagData?.length)
          ) {
            stepDetails = true;
          }
        }
      }
    });
    return stepDetails;
  };

  const getStepData = (item) => {
    const data = props.troubleshootingData;
    let stepDetails = [];
    if (
      Object.keys(data.step_activities).length === 1 ||
      data.data.length === 1
    ) {
      return stepDetails;
    }
    data.data.forEach((value) => {
      if (value.action && value.id === item.next) {
        for (let i = 0; i < value.action.length; i++) {
          const tagData = value.action[i].tag_data[0];
          if (
            tagData?.tag_data?.length ||
            (!tagData?.includes("N/A") && tagData?.length)
          ) {
            stepDetails = value.action;
          }
        }
      }
    });
    return stepDetails;
  };

  const checkIsStepDisabled = (searchItem, stepId, index) => {
    let isStepDisabled = false;
    if (index === 0) {
      isStepDisabled = true;
    } else {
      isStepDisabled = props.visitedTroubleshootingSteps.some((eachStep) => {
        if (
          eachStep.step_id === stepId &&
          eachStep.search_item === searchItem
        ) {
          return true;
        } else if (
          eachStep.step_id !== stepId &&
          eachStep.search_item === searchItem &&
          eachStep.step_number === index
        ) {
          return true;
        }
      });
    }
    return isStepDisabled;
  };

  return (
    <div className="content-container home-container">
      <LexxBreadcrumbs />
      {props.troubleshootingData === null ? null : (
        <Container>
          <LexxIconHeading text="Great! Now lets isolate the fault. Please select from the list below." />
          <div className="search-results-container marginL3">
            {props.troubleshootingData.data[0].answers.map((item, i) => {
              let temp = {}; // create a object with step number, id and search item.
              temp["key"] = i;
              temp["snippet"] = item["text"];
              temp["payload"] = {
                step_number: i + 1,
                step_id: item["next"],
                search_item: props.troubleshootingData.id,
                stepData: getStepData(item),
                isStepDisabled: checkIsStepDisabled(
                  props.troubleshootingData.id,
                  item["next"],
                  i
                ),
              };
              return (
                <LexxCard
                  key={i}
                  keyNumeric={true}
                  data={temp}
                  button={"Done"}
                  act={setStepVisited}
                />
              );
            })}
          </div>
          <LexxSessionToolbar />
        </Container>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    troubleshootingData: state.troubleshootingData, // the entire data of one troubleshooting
    visitedTroubleshootingSteps: state.visitedTroubleshootingSteps, // visited steps for that result
    isOwner: state.sessionData.isOwner, // session owner or not
  };
};

export default connect(mapStateToProps, {
  saveTroubleshootingOption,
  saveTroubleshootingStep,
  saveVisitedTroubleshootingSteps,
})(LexxTroubleshootingOptions);
