import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { Header, Menu, Button, Label, TransitionablePortal, Segment } from 'semantic-ui-react';
import { getTask, getComments, saveReportIntermediate } from '../apiCall';
import userDummy from '../../assets/images/user-dummy.png';
import LexxComment from "../LexxTask/LexxComment";
import LexxLogs from "./LexxLogs";
import SavedSessionCard from "../LexxCarousel/SavedSessionCard";
import { saveCompletedSessionOptions } from "../../redux/actions/index";
import LexxMemberModal from '../LexxTeam/LexxMemberModal';

/* This component shows the report summary in the end */
function LexxReportSummary(props) {

    const [task, setTask] = useState([]);
    const [comments, setComments] = useState([]);
    const [submitReport, setSubmitReport] = useState(false);
    const [taskReportSaved, saveTaskReportSaved] = useState(false);

     /* Function gets called when component is mounted to ge task details
        It then sets the task in task variable
    */ 
    const getTaskServerCall = () => {
        getTask("/" + props.match.params.id).then((response) => {
            setTask(response.data.data.task);
        }).catch((error) => {
            console.log(error);
        });
        getCommentsServerCall();
    }

    /* Function to get all comments from server and set it in comments variable */
    const getCommentsServerCall = () => {
        getComments("/" + props.match.params.id).then((response) => {
            setComments(response.data.data.comments);
        }).catch((error) => {
            console.log(error);
        });
    }

    /* Function to convert UTC date to readable date */
    const getDate = (dateUTC) => {
        return dateUTC.getDate() + '/' + (dateUTC.getMonth() + 1) + '/' + dateUTC.getFullYear();
    }

    /* Function to convert UTC date to readable time */
    const getTime = (dateUTC) => {
        if (dateUTC.getMinutes().toString().length === 1) {
            return dateUTC.getHours() + ":0" + dateUTC.getMinutes();
        } else {
            return dateUTC.getHours() + ":" + dateUTC.getMinutes();
        }
    }

    /* Function called when back btn is clicked
        if the user has reviewed more than 1 session, take them to previous session
        else take them to session list review page
    */
    const goBack = () => {
        if (props.completedSessionOptions.length > 0) {
            var sessionsCompleted = props.completedSessionOptions;
            sessionsCompleted.pop();
            props.saveCompletedSessionOptions(sessionsCompleted);
            props.history.push("/reviewEachSession/id=" + props.match.params.id);
        } else {
            props.history.push("/reviewSessionReport/id=" + props.match.params.id);
        }
    }

    /* When the report is saved 
        Save the report in backend
    */
    const saveReport = () => {
        saveReportIntermediate("/" + task.taskId + "/report", { "report": props.draftReport });
        saveTaskReportSaved(true);
    }

    /* This function gets all the sessions to be reviewed in a task
        if the sessions are more than 0, loop over each session and push it in array
        if the session id matches with the task sessions, show it to user
    */
    const sessionsReview = () => {
        var sessionsToShow = [];
        if (task.sessions.length > 0) {
            task.sessions.forEach((session, idx) => {
                if (props.completedSessionOptions.includes(session.sessionId)) {
                    sessionsToShow.push(<SavedSessionCard key={idx} sessionData={session} isClickable={false} />);
                } else {
                    return null
                }
            })
        } else {
            return sessionsToShow;
        }
        return sessionsToShow;

    }

    /* when the report is reviewed and submited
        @isConfirmed - true/false based on if the report is submited or not
        currStep is set to -1 to tell backend that it is submitted
    */
    const confirmSubmit = (isConfirmed) => {
        if (isConfirmed) {
            var report = props.draftReport;
            report.currStep = -1;
            saveReportIntermediate("/" + task.taskId + "/report", { "report": report });
            props.history.push("/reportGenerated/id=" + props.match.params.id);
        } else {
            setSubmitReport(false);
        }
    }

    useEffect(getTaskServerCall, []);

    return (
        <div className="session-summary report-summary-step4">
            <div className="header-container">
                <Header as="h2">Task Report</Header>
                <span className="saveDraft" onClick={() => saveReport()}>Save Task Report</span>
                <div className="task-details">{task.taskNum !== "" ? task.taskNum + " • " + task.taskTitle : ""}</div>
                <div>
                    <span className="filled"></span>
                    <span className="filled"></span>
                    <span className="filled"></span>
                    <span className="filled"></span>
                </div>
            </div>
            {
                Object.keys(task).length > 0 ?
                    <>
                        <div className="session-details">
                            <div>
                                <p className="label">Type of Task</p>
                                <p className="value">
                                    <span className={'task-priority ' + task.taskPriority.toLowerCase()}> {task.taskPriority} </span>
                                    <span className="task-type"> {task.taskType} </span>
                                </p>
                            </div>
                            <div>
                                <p className="label">Number of Sessions</p>
                                <p className="value">{props.completedSessionOptions.length}</p>
                            </div>
                            <div>
                                <p className="label">Assignee</p>
                                <p className="value assignee">
                                    <Label circular className="collaborators float-left" as='a' image={task.assignee.avatar === "" ? userDummy : task.assignee.avatar} />
                                    <span>{task.assignee.name}</span>
                                </p>
                            </div>
                            <div>
                                <p className="label">Date and time started</p>
                                {/* <p className="value">{getDate(new Date(sessionData.createTime)) + " " + getTime(new Date(sessionData.createTime))}</p> */}
                            </div>
                            <div>
                                <p className="label">Date and time finished</p>
                                <p className="value">{getDate(new Date(task.completeTime)) + " " + getTime(new Date(task.completeTime))}</p>
                            </div>
                        </div>
                        <Header as="h3">Sessions</Header>
                        <div className="sliding-cards">
                            {sessionsReview()}
                        </div>
                        <div>
                            <Header as="h3">Comments</Header>
                            {comments.map((comment, i) => <div key={i} className="commentsWrapper"><LexxComment comment={comment} /></div>)}
                        </div>

                        <div className="review-logs">
                            <Header as="h3">Logs</Header>
                            {task.logs !== undefined ? task.logs.map((log, i) => <div key={i} className="logsWrapper"><LexxLogs log={log} /></div>) : null}
                        </div>
                    </>
                    : null
            }

            <Menu borderless fixed='bottom' id="menu">
                <Menu.Item position='left'>
                    <Button className='secondarybtn' onClick={() => goBack()}>
                        Back
                    </Button>
                </Menu.Item>
                <Menu.Item position='right'>
                    <Button className='primarybtn' onClick={() => setSubmitReport(true)} >
                        Submit Report
                    </Button>
                </Menu.Item>
            </Menu>
            {
                submitReport ?
                    <LexxMemberModal open={submitReport} closeDialog={setSubmitReport} confirmOption={confirmSubmit} icon={"file outline"} iconColor={"blue"}
                        text={"Are you ready to submit the report?"}
                        buttonPrimaryText="Yes" buttonSecondaryText="No" classSecondaryBtn="grey-btn" />
                    : null
            }
            <TransitionablePortal
                open={taskReportSaved}
                onClose={() => saveTaskReportSaved(false)}
            >
                <Segment
                    style={{ left: '25%', position: 'fixed', top: '2%', zIndex: 11000, width: '220px' }}
                    className='green-error'
                >
                    <p>Task Report Saved</p>
                </Segment>
            </TransitionablePortal>
        </div>

    )
}

const mapStateToProps = (state) => {
    return {
        userId: state.userInfo.userId,
        completedSessionOptions: state.completedSessionOptions,
        draftReport: state.draftReport
    };
};

export default withRouter(connect(mapStateToProps, { saveCompletedSessionOptions })(LexxReportSummary));