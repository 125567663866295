import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { Input, Icon, Header, Label } from 'semantic-ui-react';
import { getAllSessions, getSessionUnreadMessages } from '../apiCall';
import userDummy from '../../assets/images/user-dummy.png';

/* Component to show list of the sessions */
function LexxAllSessions(props) {

    const [search, setSearch] = useState(""); //search query for sessions 
    const [sessions, setSessions] = useState(""); // session list which changes when we search
    const [stableSessions, setStableSessions] = useState(""); // stable session list
    const [unreadMessages, setUnreadMessages] = useState([]);

    /* This function get all the sessions from the server
        and sets it in the variable sessions and stableSessions
    */
    const getSessions = () => {
        getAllSessions().then((response) => {
            setStableSessions(response.data.data);
            setSessions(response.data.data);
            getUnreadMessages();
        }).catch((error) => {
            console.log(error);
        });
    }

    useEffect(getSessions, []);
    
    /* Function called when the component is mounted
        get all the unread Messages for the session and 
        set it in unreadmessages variable
     */
    const getUnreadMessages = () => {
        getSessionUnreadMessages().then((response) => {
            setUnreadMessages(response.data.data.counters);
        }).catch((error) => {
            console.log(error);
        });
    }

    /* Function to convert UTC date to readable date */
    const getDate = (dateUTC) => {
        return dateUTC.getDate() + '/' + (dateUTC.getMonth() + 1) + '/' + dateUTC.getFullYear();
    }

    /* Function to convert UTC date to readable time */
    const getTime = (dateUTC) => {
        if (dateUTC.getMinutes().toString().length === 1) {
            return dateUTC.getHours() + ":0" + dateUTC.getMinutes();
        } else {
            return dateUTC.getHours() + ":" + dateUTC.getMinutes();
        }
    }

    /* Function called when the user searches for a session
        @event - event returned from UI for search query
        filter the stable Sessions and search for matched sessions
    */
    const changeSearch = (event) => {
        setSearch(event.currentTarget.value);
        const filteredData = stableSessions.filter(item => {
            return Object.keys(item).some((eachKey) => {
                if (eachKey === "searchQuery") {
                    return item[eachKey].toLowerCase().includes(event.currentTarget.value.toLowerCase());
                } else {
                    return null;
                }
            })
        });
        setSessions(filteredData);
    }

    /* Function to get color based on status and return class for css
        @status - the status of the session (paused, inprogress or completed)
    */
    const getColor = (status) => {
        switch (status.toLowerCase()) {
            case "paused":
                return "task-status yellow";
            case "inprogress":
                return "task-status green";
            default:
                return "task-status green"
        }
    }

    /* Redirect the user to the session detail page */
    const extractSession = (sessionID) => {
        props.history.push("/session/id="+sessionID);
    }

    /* This message is used to get all unread messages 
        and map the unread message only for this session
        @item - has the data of current session
    */
    const checkMessages = (item) => {
        var data = 0;
        if (unreadMessages.length > 0) {
            unreadMessages.forEach(function (eachMessage) {
                if (item.sessionId === eachMessage.sessionId) {
                    data = eachMessage.unread;
                }
            });
            return data;
        }
        return data;
    }

    return (
        <div className="my-sessions">
            <Header as="h2">My Sessions</Header>
            <div className="searchTeam">
                <Input
                    icon={<Icon name='search' color="black" />}
                    iconPosition='left' placeholder='Search' value={search} onChange={changeSearch} />
            </div>
            {sessions.length > 0 && sessions.map((item, key) => {
                return (
                    <div key={key} className="each-session" onClick={() => extractSession(item.sessionId)}>
                        <div className="time"> {getDate(new Date(item.createTime)) + " " + getTime(new Date(item.createTime))} </div>
                        <div className="query"> {item.searchQuery} </div>
                        {
                            !item.troubleshootingData ? " " :
                                <div className="task-details">
                                    <div className="task-name"> {item.troubleshootingData.info_type === "Examinations, tests & checks" ? "Work Instruction" : item.troubleshootingData.info_type} • {item.troubleshootingData.title} </div>
                                </div>
                        }
                        <div className="info">
                            <Label circular className="collaborators" as='a' image={item.owner.avatar} />
                            {
                                item.guests.map((collab_id, k) => {
                                    if (collab_id.avatar !== "") {
                                        return <Label key={k} circular className="collaborators" as='a' image={collab_id.avatar} />
                                    } else {
                                        return <Label key={k} circular className="collaborators" as='a' image={userDummy} />
                                    }
                                })
                            }
                            {checkMessages(item) > 0 ?
                                <span className="notification-number allSession">{checkMessages(item)}</span> : null
                            }
                            {
                                !item.task ? " " :
                                    <div className={getColor(item.task.status) + " marT10"}> {item.completeTime !== undefined ? 'Completed' : 'In Progress'} </div>
                            }
                            {
                                item.owner.id !== props.userId ? <div className="shared-me"> Shared with me </div>  : null
                            }
                        </div>
                    </div>
                )
            }).reverse()}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        userId: state.userInfo.userId
    };
};

export default withRouter(connect(mapStateToProps)(LexxAllSessions));