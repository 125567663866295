import React from 'react';
import { Menu, Button } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { setSearchType, saveTaskId } from '../../redux/actions';

/* Stateless component to show the buttons at the bottom
    based on if the task owner is vieweing it 
    if the person assigned has accepted or not
    if the person has accepted and is not the owner
    */
function BottomPanelButtons(props) {

    return (
        props.ownerView || props.isEditable ?
            props.isEditable ?
                props.task.status === 'completed' ?
                    props.task.report !== undefined ?
                        props.task.report.currStep !== -1 ?
                            <Menu borderless fixed='bottom' id="menu">
                                <Button className='generate-report' onClick={() => props.loadReport()}>
                                    Continue Editing close-out Report
                                </Button>
                            </Menu>
                            :
                            props.task.closed === undefined && props.task.owner.userId === props.userId ?
                                <Menu borderless fixed='bottom' id="menu">
                                    <Button className='generate-report' onClick={() => props.closeTask()}>
                                        Close Task
                                    </Button>
                                </Menu>
                                : null
                        :
                        <Menu borderless fixed='bottom' id="menu">
                            <Button className='generate-report' onClick={() => props.history.push("/createReport/id=" + props.task.taskId)}>
                                Generate Report
                            </Button>
                        </Menu>
                    :
                    <Menu borderless fixed='bottom' id="menu">
                        <Menu.Item position='left'>
                            <Button
                                className='ts-action-btn'
                                disabled={props.task.status === 'inprogress' ? false : true}
                                onClick={() => {
                                    props.setSearchType(true);
                                    props.saveTaskId(props.task.taskId);
                                    props.history.push('/troubleshooting')
                                }}
                            >
                                New Session
                            </Button>
                        </Menu.Item>
                        <Menu.Item position='right'>
                            <Button
                                color='blue'
                                className='ts-action-btn'
                                disabled={props.task.status === 'inprogress' ? false : true}
                                onClick={() => props.handleChange(100)}
                            >
                                Fault is fixed
                            </Button>
                        </Menu.Item>
                    </Menu>
                :
                props.task.status === 'completed' && props.task.report !== undefined && props.task.report.currStep === -1 ?
                    props.task.closed !== undefined ?
                        <Menu borderless fixed='bottom' id="menu">
                            <Button className='generate-report' onClick={() => props.history.push("/reviewReportSummary/id=" + props.match.params.id)}>
                                View Report
                            </Button>
                        </Menu>
                        :
                        <Menu borderless fixed='bottom' id="menu">
                            <Button className='generate-report' onClick={() => props.history.push("/reviewReportSummary/id=" + props.match.params.id)}>
                                Review Report
                            </Button>
                        </Menu>
                    : null
            :
            <Menu borderless fixed='bottom' id="menu">
                <Menu.Item position='left'>
                    <Button
                        className='ts-action-btn'
                        onClick={() => props.taskDecision(false)}
                    >
                        Decline Task
                    </Button>
                </Menu.Item>
                <Menu.Item position='right'>
                    <Button
                        color='blue'
                        className='ts-action-btn'
                        onClick={() => props.taskDecision(true)}
                    >
                        Accept Task
                    </Button>
                </Menu.Item>
            </Menu>

    )
}

const mapStateToProps = (state) => {
    return {
        userId: state.userInfo.userId
    };
};

export default withRouter(connect(mapStateToProps, { setSearchType, saveTaskId })(BottomPanelButtons));