import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { Header, Label, Progress, Dropdown, Icon, Button } from 'semantic-ui-react';
import { saveReduxDraftReport, saveSessionOptions, saveCompletedSessionOptions } from "../../redux/actions/index";
import { getTask, updateTask, taskAssignResult, markNotificationRead, delegateTaskToTeamMember, addComment as addCommentAPI, getComments, deleteComment as deleteCommentAPI, editComment as editCommentAPI, closeTaskServer } from '../apiCall';
import LexxDelegateTask from "./LexxDelegateTask";
import InputRange from 'react-input-range';
import SavedSessionCard from "../LexxCarousel/SavedSessionCard";
import BottomPanelButtons from "./BottomPanelButtons";
import LexxDeclineModal from "./LexxDeclineModal";
import LexxComment from "./LexxComment";
import 'react-input-range/lib/css/index.css';

/* This is the component to display the Task details */
function LexxTask(props) {

    /* Status options created for dropdown */
    const options = [
        {
            key: 1,
            text: (
                <>
                    <span className={'update inprogress'}></span>
                    <span className='status'>  In Progress </span>
                </>
            ),
            value: 'inprogress',
            content: (
                <>
                    <span className={'update inprogress'}></span>
                    <span className='status'>  In Progress </span>
                </>
            ),
        },
        {
            key: 2,
            text: (
                <>
                    <span className={'update paused'}></span>
                    <span className='status'>  Paused </span>
                </>
            ),
            value: 'paused',
            content: (
                <>
                    <span className={'update paused'}></span>
                    <span className='status'>  Paused </span>
                </>
            ),
        },
        {
            key: 3,
            text: (
                <>
                    <span className={'update blocked'}></span>
                    <span className='status'>  Blocked </span>
                </>
            ),
            value: 'blocked',
            content: (
                <>
                    <span className={'update blocked'}></span>
                    <span className='status'>  Blocked </span>
                </>
            ),
        },
        {
            key: 5,
            text: (
                <>
                    <span className={'update completed'}></span>
                    <span className='status'> Completed </span>
                </>
            ),
            value: 'completed',
            content: (
                <>
                    <span className={'update completed'}></span>
                    <span className='status'> Completed </span>
                </>
            )
        }
    ]


    const [task, setTask] = useState("");
    const [delegateModal, setDelegateModal] = useState(false);
    const [pendingRequest, setPendingRequest] = useState(undefined);
    const [ownerView, setOwnerView] = useState(false);
    const [showDeclineModal, setShowDeclineModal] = useState(false);
    const [reason, setReason] = useState("");
    const [isEditable, setEditable] = useState(false);
    const [error, setError] = useState(false);
    const [comments, setComments] = useState([]);
    const [comment, setComment] = useState("");
    const [editCommentFlag, setEditComment] = useState("");
    const [editedComment, setEditedComment] = useState("");

    /* Function to get the task details from server
        set the task data (setTask)
        check if there is any pending request
        and get comments from server 
    */
    const getTaskServerCall = () => {
        var serverCall = props.location.pathname.lastIndexOf("notifId=") > -1 ? "/" + props.match.params.id + "?invitee=true" : "/" + props.match.params.id;
        getTask(serverCall).then((response) => {
            let taskData = response.data.data.task;
            setTask(taskData);
            getPendingRequest(taskData);
            getCommentsServerCall(taskData.taskId);
        }).catch((error) => {
            console.log(error);
        });
    }

    useEffect(getTaskServerCall, []);


    /* Function called when status is changed 
        if status is changed by progress bar  (100 - completed)
        or by dropdown
    */
    const handleChange = (value) => {
        setTask({ ...task, progress: value });
        if (value === 100) {
            updateTask("/" + task.taskId + "/status", { "status": "completed" }).then((response) => {
                getTaskServerCall();
                setTask({ ...task, status: "completed" });
            }).catch((error) => {
                console.log(error);
            });
        } else {
            if (task.status === 'completed') {
                setTask({ ...task, status: "inprogress" });
                updateTask("/" + task.taskId + "/status", { "status": "inprogress" });
            }
            updateTask("/" + task.taskId + "/progress", { "progress": value });
        }

    }

    /* Function get if the task is assigned to someone and not accepted/declined
        get the data from the server
        @taskData - contains the info of the task
    */
    const getPendingRequest = (taskData) => {
        var id = taskData.taskId;
        getTask("/" + id + "/invitee").then((response) => {
            let pendingData = response.data.data;
            setPendingRequest(pendingData);
            /// Pending case
            if (pendingData !== undefined) {
                if (taskData.assignee.userId === props.userId) {
                    setOwnerView(true);
                    setEditable(true);
                } else {
                    setOwnerView(false);
                }
            } else {
                if (taskData.assignee.userId === props.userId) {
                    setEditable(true);
                } else {
                    setOwnerView(true);
                    setEditable(false);
                }
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    /* Change the task status
        set the task in the variable
        send new status to server
        @e - event from UI
        @data - data is the new task value 
    */
    const statusChange = (e, data) => {
        setTask({ ...task, status: data.value });
        if (data.value === 'completed') {
            handleChange(100);
        } else {
            updateTask("/" + task.taskId + "/status", { "status": data.value });
        }
    }

    /* Called when the person assigned need to make a decision on task delegation
        if accepted , make call to server
        if declined, show the delete modal
    */
    const taskDecision = (isAccept) => {
        if (isAccept) {
            decisionCall(isAccept);
        } else {
            setShowDeclineModal(true);
        }
    }

    /* This function updates the server with the acceptance or decline
        @isAccept - true/false
    */
    const decisionCall = (isAccept) => {
        var path = props.location.pathname;
        var data = { "accepted": isAccept, "responseData": reason };
        if (isAccept || (!isAccept && reason !== "")) {
            setShowDeclineModal(false);
            taskAssignResult(path.slice(path.lastIndexOf("notifId=") + 8), data).then(() => {
                if (isAccept){
                    props.history.push(`/viewTask/id=${task.taskId}`);
                    props.history.go(0)
                }else{
                    props.history.push("/")
                }
            }).catch((error) => {
                console.log(error);
            });
            markasRead(path.slice(path.lastIndexOf("notifId=") + 8));
        } else {
            setError(true);
        }

    }

    /* Mark the notification as read */
    const markasRead = (notificationId) => {
        markNotificationRead("/" + notificationId + "/read");
    }

    /* This function is called when the task is assigned to a user
        @invitee - user the task is assigned to 
        send the request to the user (to the server)
    */
    const delegateTaskToUser = (invitee) => {
        const data = { taskId: task.taskId, assigneeId: invitee };
        delegateTaskToTeamMember(data).then((response) => {
            setDelegateModal(false);
            getTaskServerCall();
        }).catch((e) => {
            console.log(e);
        })
    }

    /* This is called when component is mounted 
        gets all the comments for a task based on task id and sets it
        @id - task id of the task

    */
    const getCommentsServerCall = (id) => {
        let taskId = id === undefined ? task.taskId : id;
        getComments("/" + taskId).then((response) => {
            setComments(response.data.data.comments);
        }).catch((error) => {
            console.log(error);
        });
    }

    /* When the comment is added 
        Send the new comment to server 
    */
    const addComment = () => {
        const data = { taskId: task.taskId, text: comment };
        addCommentAPI(data).then(() => {
            getCommentsServerCall();
            setComment("");
        }).catch((e) => {
            console.log(e);
        })
    }

    /* If the user decides to edit a comment 
        @commentID - comment id
        @text - new text
        */
    const editComment = (commentID, text) => {
        setEditComment(commentID);
        setEditedComment(text);
    }

    /* When the comment is edited and saved 
        Send the new comment to server 
        @commentID - comment id
    */
    const editSavedComment = (commentID) => {
        const data = { text: editedComment };
        editCommentAPI("/" + commentID, data).then(() => {
            getCommentsServerCall(); // get all comments
            setEditComment("");
            setEditedComment("");
        }).catch((e) => {
            console.log(e);
        })
    }

    /* When the comment is deleted 
        Send the comment id to server 
        @commentID - comment id
    */
    const deleteComment = (commentId) => {
        deleteCommentAPI("/" + commentId + "/inactive").then(() => {
            getCommentsServerCall();
        }).catch((error) => {
            console.log(error);
        });
    }

    /* Get the user image
        Check if the comment owner or other user
    */
    const getCommentImage = () => {
        if(!ownerView && !isEditable && pendingRequest !== undefined){ // if the user has assess to it 
            return pendingRequest.assignee.avatar;
        }
        if (task.assignee.userId === props.userId) { // task assigned user is same as logged in user
            return task.assignee.avatar;
        } else {
            return task.owner.avatar; // return owner avatar
        }
    }   


    /* This function is to load the report for the task
        load all the sessions in the task
        and load the report from redix
    */
    const loadReport = () => {
        var step = task.report.currStep;
        var configs = task.report.sessionConfigs;
        var allSessionOptionsChosen = [];
        var completedSessions = [];
        for (var i = 0; i < configs.length; i++) {
            allSessionOptionsChosen.push(configs[i].sessionId);
            if (step > i) completedSessions.push(configs[i].sessionId);
        };
        props.saveReduxDraftReport(task.report);
        props.saveSessionOptions(allSessionOptionsChosen);
        props.saveCompletedSessionOptions(completedSessions);
        if (step === configs.length) {
            props.history.push("/reviewReport/id=" + props.match.params.id);
        } else {
            props.history.push("/reviewEachSession/id=" + props.match.params.id);
        }
    }

    /* Function called if the user has closed the task 
        Send request to server 
    */
    const closeTask = () => {
        closeTaskServer("/" + props.match.params.id + "/closed");
    }

    /* if the user presses the enter key
        add the comment
        */
    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            addComment();
        }
    }

    return (
        <>
            {
                typeof (task) !== 'string' ?
                    <div className="task-overview">
                        <div className="task-details">
                            <div className="task-num">{task.taskNum}</div>
                            <Header as="h2" className="task-title">{task.taskTitle}</Header>
                            <div className="task-details">
                                <span className={'task-priority ' + task.taskPriority.toLowerCase()}> {task.taskPriority} </span>
                                <span className="task-type"> {task.taskType} </span>
                            </div>
                            <div className="progress-bar">
                                <div>
                                    <span className="progress-title">Progress</span>
                                    <span className="progress-percent">{task.progress}%</span>
                                </div>
                                {
                                    task.report === undefined && ((ownerView && isEditable) || isEditable) ?
                                        <InputRange
                                            maxValue={100}
                                            minValue={0}
                                            value={task.progress}
                                            step={10}
                                            onChange={handleChange} /> :
                                        <Progress percent={task.progress} />
                                }
                            </div>
                            {
                                task.report !== undefined && task.report.currStep === -1 ?
                                    <div className="close-report-wrapper">
                                        <Icon name="sticky note outline" color="blue" />
                                        <span>Close out report</span>
                                        <Button onClick={() => props.history.push("/reviewReportSummary/id=" + props.match.params.id)}>
                                            View Report
                                        </Button>
                                    </div>
                                    : null
                            }

                            <div className="description">
                                <Header as="h4">Description</Header>
                                <p>{task.taskDescription}</p>
                            </div>
                        </div>
                        <div className="assignee marT20">
                            <Header as="h4">Assignee</Header>
                            {pendingRequest === undefined ?
                                <div>
                                    <Label circular className="collaborators float-left" as='a' image={task.assignee.avatar} />
                                    <span>{task.assignee.name}</span>
                                    {
                                        task.assignee.userId === task.owner.userId && task.report === undefined ?
                                            <span className="handOver" onClick={() => setDelegateModal(true)}>Hand Over Task</span>
                                            : null
                                    }
                                </div>
                                :
                                <div>
                                    <Label circular className="collaborators float-left" as='a' image={pendingRequest.assignee.avatar} />
                                    <span>{pendingRequest.assignee.name}</span>
                                    <span className="handOver pending">Pending</span>
                                </div>
                            }
                        </div>
                        <div className="status-avatar">
                            <Header as="h4">Status</Header>
                            {
                                task.report === undefined && ((ownerView && isEditable) || isEditable) ?
                                    <div>
                                        <Dropdown options={options} scrolling upward value={task.status} wrapSelection={false} onChange={statusChange} />
                                    </div>
                                    :
                                    <div className="status-avatar taskDetailsStatus">
                                        <span className={ownerView ? 'update delegated' : 'update ' + task.status}></span>
                                        <span className='status'> {ownerView ? 'Delegated' : task.status === 'inprogress' ? 'In Progress' : task.status.slice(0, 1).toUpperCase() + task.status.slice(1,)} </span>
                                    </div>
                            }

                        </div>
                        <div className="sessions">
                            <Header as="h4">Associated Sessions</Header>
                            <span className="session-number">{task.sessions.length} Sessions</span>
                            <div className="sliding-cards">
                                {
                                    task.sessions.map((session, idx) => {
                                        return <SavedSessionCard key={idx} sessionData={session} isClickable={(ownerView || isEditable)} />
                                    }).reverse() // latest session displayed first
                                }
                            </div>
                        </div>
                        <div className="comments">
                            <Header as="h4">Comments</Header>
                            <div className="comments-add">
                                {comments.map((comment, i) => <div key={i} className="commentsWrapper"><LexxComment comment={comment} loggedInUser={props.userId} deleteComment={deleteComment} editComment={editComment} editing={editCommentFlag === comment.commentId ? true : false} editSavedComment={editSavedComment} setEditedComment={setEditedComment} editedComment={editedComment} /></div>)}
                                <Label circular className="collaborators float-left" as='a' image={getCommentImage()} />
                                <input placeholder='Add a note' name="title" value={comment} onChange={(e) => setComment(e.target.value)} onKeyPress={handleKeyPress} />
                                <Icon name="send" size="big" className="marL10" onClick={() => addComment()} />
                            </div>
                        </div>
                        <BottomPanelButtons task={task} ownerView={ownerView} handleChange={handleChange} taskDecision={taskDecision} isEditable={isEditable} loadReport={loadReport} closeTask={closeTask} />
                    </div>
                    : null
            }
            {
                delegateModal ? <LexxDelegateTask open={delegateModal} closeDialog={setDelegateModal} delegateTaskToUser={delegateTaskToUser} /> : null
            }
            {
                showDeclineModal ? <LexxDeclineModal open={showDeclineModal} closeDialog={setShowDeclineModal} taskNum={task.taskNum} reason={reason} setReason={setReason} decisionCall={decisionCall} error={error} /> : null
            }
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        userId: state.userInfo.userId
    };
};
export default withRouter(connect(mapStateToProps, { saveReduxDraftReport, saveSessionOptions, saveCompletedSessionOptions })(LexxTask));