import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { Input, Icon, Header, Label, Progress, Button, Modal, Form, Dropdown } from 'semantic-ui-react';
import { getTaskList, createTask, getTeam} from '../apiCall';

/* This Component shows the tasks list associated to the logged in user */
function LexxAllTask(props) {

    const [search, setSearch] = useState(""); //search query for tasks 
    const [tasks, setTasks] = useState(""); // task list which changes when we search
    const [stableTasks, setStateTasks] = useState(""); // stable task list
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState({
        assigneeId: '',
        taskNum: '',
        taskTitle: '',
        taskDescription: '',
        taskType: '',
        taskPriority: '',
        expectedDeliveryDate: '',
      });
    const [assigneeOptions, setAssigneeOptions] = useState([]);

    const handleChange = (e, { name, value }) => {
        setFormData({ ...formData, [name]: value });
    };
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleSubmit = () => {
        // Perform your form submission logic here, e.g., send data to an API
        createTask(formData);
        // Reset the form after submission
        setFormData({
            assigneeId: '',
            taskNum: '',
            taskTitle: '',
            taskDescription: '',
            taskType: '',
            taskPriority: '',
            expectedDeliveryDate: '',
        });
    
        // Close the modal or perform any other actions after submission
        handleClose();
    }

    /* This function get all the tasks from the server
        and sets it in the variable tasks and setStateTasks
    */
    const getTasks = () => {
        getTaskList().then((response) => {
            setStateTasks(response.data.data.tasks);
            setTasks(response.data.data.tasks);
        }).catch((error) => {
            console.log(error);
        });
    }

    const getTeamMembers = () => {
        getTeam().then((res) => {
            let users = res.data.data.team;
            let userOptions = [];
            for (let i=0; i<users.length; i++) {
                userOptions.push({
                    key: users[i].userId, text: users[i].name, value: users[i].userId
                })
            }
            setAssigneeOptions(userOptions);
        })
    }

    useEffect(getTasks, []);
    useEffect(getTeamMembers, []);

    /* Function called when the user searches for a task
        @event - event returned from UI for search query
        filter the stable Tasks and search for tasks
    */
    const changeSearch = (event) => {
        setSearch(event.currentTarget.value);
        const filteredData = stableTasks.filter(item => {
            return Object.keys(item).some((eachKey) => {
                if (eachKey === "searchQuery") {
                    return item[eachKey].toLowerCase().includes(event.currentTarget.value.toLowerCase());
                } else {
                    return null;
                }
            })
        });
        setTasks(filteredData);
    }

    /* Redirect the user to the task detail page */
    const taskClicked = (taskId) => {
        props.history.push(`/viewTask/id=${taskId}`);
    }

    return (
        <div className="my-sessions">
            <Header as="h2">My Tasks</Header>
            <div className='right-float'>
                <Button 
                    className='new-task-button' 
                    onClick={handleOpen}
                    content='Create a Task'
                />
            </div>
            <Modal open={open} onClose={handleClose} size='tiny'>
                <Modal.Header>Enter new Task details</Modal.Header>
                <Modal.Content>
                <Form>
                    <Form.Field>
                        <label>Assign to</label>
                        <Dropdown
                            name='assigneeId'
                            placeholder='Select Assignee'
                            fluid
                            selection
                            options={assigneeOptions}
                            // value={formData.assigneeId}
                            onChange={handleChange}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>taskNum</label>
                        <Input 
                            name='taskNum'
                            value={formData.taskNum}
                            onChange={handleChange}
                            placeholder='Enter your taskNum' 
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>taskTitle</label>
                        <Input 
                            name='taskTitle'
                            value={formData.taskTitle}
                            onChange={handleChange}
                            placeholder='Enter your taskTitle' />
                    </Form.Field>
                    <Form.Field>
                        <label>taskDescription</label>
                        <Input 
                            name='taskDescription'
                            value={formData.taskDescription}
                            onChange={handleChange}
                            placeholder='Enter your taskDescription' />
                    </Form.Field>
                    <Form.Field>
                        <label>taskType</label>
                        <Input 
                            name='taskType'
                            value={formData.taskType}
                            onChange={handleChange}
                            placeholder='Enter your taskType' />
                    </Form.Field>
                    <Form.Field>
                        <label>taskPriority</label>
                        <Input 
                            name='taskPriority'
                            value={formData.taskPriority}
                            onChange={handleChange}
                            placeholder='Enter your taskPriority' />
                    </Form.Field>
                    <Form.Field>
                        <label>Expected delivery date</label>
                        <Input 
                            type='date'
                            name='expectedDeliveryDate'
                            value={formData.expectedDeliveryDate}
                            onChange={handleChange}
                            placeholder='Enter your expected delivery date' />
                    </Form.Field>
                </Form>
                </Modal.Content>
                <Modal.Actions>
                <Button color='black' onClick={handleClose}>
                    Cancel
                </Button>
                <Button
                    labelPosition='right'
                    content='Submit'
                    onClick={handleSubmit}
                />
                </Modal.Actions>
            </Modal>
            <div className="searchTeam">
                <Input
                    icon={<Icon name='search' color="black" />}
                    iconPosition='left' placeholder='Search' value={search} onChange={changeSearch} />
            </div>
            {tasks.length > 0 && tasks.map((item, key) => {
                return (
                    <div key={key} className="each-session taskList" onClick={() => taskClicked(item.taskId)}>
                        <div className="each-task">
                            <div className="time"> {item.taskNum} </div>
                            <div className="query"> {item.taskTitle} </div>
                            <div className="task-details">
                                <span className={'task-priority ' + item.taskPriority.toLowerCase()}> {item.taskPriority} </span>
                                <span className="task-type"> {item.taskType} </span>
                            </div>
                            <div className="status-avatar">
                                <span className={props.userId !== item.assignee.userId ? 'update delegated' : 'update ' + item.status}></span>
                                <span className='status'>{props.userId !== item.assignee.userId ? 'Delegated' :  item.status === 'inprogress' ? 'In Progress' : item.status.slice(0, 1).toUpperCase() + item.status.slice(1,)} </span>
                                <Label circular className="collaborators float-right" as='a' image={item.assignee.avatar} />
                            </div>
                        </div>
                        {
                            item.progress !== undefined ?
                                <div className="progress-bar">
                                    <div>
                                        <span className="progress-title">Progress</span>
                                        <span className="progress-percent">{item.progress}%</span>
                                    </div>
                                    <Progress percent={item.progress} />
                                </div>
                                : null
                        }
                    </div>
                )
            })}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        userId: state.userInfo.userId
    };
};

export default withRouter(connect(mapStateToProps, null)(LexxAllTask));