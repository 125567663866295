import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { Header, Menu, Button } from 'semantic-ui-react';
import { getComments, getTask, deleteComment as deleteCommentAPI, editComment as editCommentAPI } from '../apiCall';
import LexxComment from "../LexxTask/LexxComment";
import LexxLogs from "./LexxLogs";

/* This is the component for reviewing the comments for the report */
function LexxReviewComments(props) {

    const [comments, setComments] = useState([]);
    const [taskId, setTaskId] = useState("");
    const [taskName, setTaskName] = useState("");
    const [editCommentFlag, setEditComment] = useState("");
    const [editedComment, setEditedComment] = useState("");
    const [logs, setLogs] = useState([]);

    /* This is called when component is mounted 
        gets all the comments for a task based on task id and sets it
    */
    const getCommentsServerCall = () => {
        getComments("/" + props.match.params.id).then((response) => {
            setComments(response.data.data.comments);
        }).catch((error) => {
            console.log(error);
        });
        getTaskServerCall(props.match.params.id)
    }

    /* Function to get the task details from server
        @id - task id
        set the task id, name in the taskId and TaskName variable
        also set the task logs in logs variable
    */
    const getTaskServerCall = (id) => {
        getTask("/" + id).then((response) => {
            setTaskId(response.data.data.task.taskNum);
            setTaskName(response.data.data.task.taskTitle);
            setLogs(response.data.data.task.logs);
        }).catch((error) => {
            console.log(error);
        });
    }

    /* If the user decides to edit a comment before generated report
        @commentID - comment id
        @text - new text
    */
    const editComment = (commentID, text) => {
        setEditComment(commentID);
        setEditedComment(text);
    }

    /* When the comment is edited and saved 
        Send the new comment to server 
        @commentID - comment id
    */
    const editSavedComment = (commentID) => {
        const data = { text: editedComment };
        editCommentAPI("/" + commentID, data).then(() => {
            getCommentsServerCall(); // get all comments
            setEditComment("");
            setEditedComment("");
        }).catch((e) => {
            console.log(e);
        })
    }

 /* When the comment is deleted 
        Send the comment id to server 
        @commentID - comment id
    */
    const deleteComment = (commentId) => {
        deleteCommentAPI("/" + commentId + "/inactive").then(() => {
            getCommentsServerCall(); // get all comments
        }).catch((error) => {
            console.log(error);
        });
    }

    /* Redirect the user to the session review page
    */
    const continueBtn = () => {
        // if(sessions.length > 0){
            props.history.push("/reviewSessionReport/id=" + props.match.params.id);
        // }else{
        //     props.history.push("/reviewReport/id=" + props.match.params.id);
        // }
        
    }
 
    useEffect(getCommentsServerCall, []);

    return (
        <div className="report-summary">
            <div className="header-container">
                <Header as="h2">Generate Report</Header>
                <div className="task-details">{taskId !== "" ? taskId + " • " + taskName : ""}</div>
                <div>
                    <span className="filled"></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
            <div className="body-container">
                <div className="review-comments">
                    <Header as="h3">Review Task Comments</Header>
                    {comments.map((comment, i) => <div key={i} className="commentsWrapper"><LexxComment comment={comment} loggedInUser={props.userId} deleteComment={deleteComment} editComment={editComment} editing={editCommentFlag === comment.commentId ? true : false} editSavedComment={editSavedComment} setEditedComment={setEditedComment} editedComment={editedComment} /></div>)}
                </div>
                <div className="review-logs">
                    <Header as="h3">Review Logs</Header>
                    {logs.map((log, i) => <div key={i} className="logsWrapper"><LexxLogs log={log} /></div>)}
                </div>
            </div>
            <Menu borderless fixed='bottom' id="menu">
                <Menu.Item position='left'>
                    <Button className='secondarybtn' onClick={() => props.history.goBack()}>
                        Back
                    </Button>
                </Menu.Item>
                <Menu.Item position='right'>
                    <Button className='primarybtn' onClick={() => continueBtn()}>
                        Continue
                    </Button>
                </Menu.Item>
            </Menu>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        userId: state.userInfo.userId
    };
};

export default withRouter(connect(mapStateToProps)(LexxReviewComments));