import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { Header, Label, Checkbox, Menu, Button } from 'semantic-ui-react';
import { getSessionByTaskId, getTask } from '../apiCall';
import userDummy from '../../assets/images/user-dummy.png';
import { saveSessionOptions, saveCompletedSessionOptions } from "../../redux/actions/index";


/* Component to show list of the sessions for review of report */
function LexxReportSessions(props) {

    const [sessions, setSessions] = useState([]);  // array to contain the sessions
    const [taskId, setTaskId] = useState(""); // task id
    const [taskName, setTaskName] = useState(""); // task name
    const [selectedSessions, setSelectedSessions] = useState([]); // array to hold the session selected

    /* This function get all the sessions from the server
        and sets it in the variable sessions
    */
    const getSessions = () => {
        getSessionByTaskId("?taskId=" + props.match.params.id).then((response) => {
            setSessions(response.data.data);
        }).catch((error) => {
            console.log(error);
        });
        getTaskServerCall(props.match.params.id);
    }

    /* This function gets the task based on the task id
        @id - task id
        and sets the id , name in task id and name
    */
    const getTaskServerCall = (id) => {
        getTask("/" + id).then((response) => {
            setTaskId(response.data.data.task.taskNum);
            setTaskName(response.data.data.task.taskTitle);
        }).catch((error) => {
            console.log(error);
        });
    }

    useEffect(getSessions, []);

    /* Function to convert UTC date to readable date */
    const getDate = (dateUTC) => {
        return dateUTC.getDate() + '/' + (dateUTC.getMonth() + 1) + '/' + dateUTC.getFullYear();
    }

    /* Function to convert UTC date to readable time */
    const getTime = (dateUTC) => {
        if (dateUTC.getMinutes().toString().length === 1) {
            return dateUTC.getHours() + ":0" + dateUTC.getMinutes();
        } else {
            return dateUTC.getHours() + ":" + dateUTC.getMinutes();
        }
    } 

    /* Function to get color based on status and return class for css
        @status - the status of the session (paused, inprogress or completed)
    */
    const getColor = (status) => {
        switch (status.toLowerCase()) {
            case "paused":
                return "task-status yellow";
            case "in progress":
                return "task-status green";
            default:
                return "task-status green"
        }
    }

    /* This function takes the user to next step 
        where he/she can review each session which they selected
        2 calls to redux are made, one to save the selected option
        and another one to empty the completed session for review
    */
    const nextStep = () => {
        props.saveSessionOptions(selectedSessions);
        props.saveCompletedSessionOptions([]);
        props.history.push("/reviewEachSession/id=" + props.match.params.id);
    }


    return (
        <div className="session-summary my-sessions report-sessions">
            <div className="header-container">
                <Header as="h2">Generate Report</Header>
                <div className="task-details">{taskId !== "" ? taskId + " • " + taskName : ""}</div>
                <div>
                    <span className="filled"></span>
                    <span className="filled"></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
            <Header as="h3">Select sessions to include</Header>
            {sessions.length > 0 && sessions.map((item, key) => {
                return (
                    <div key={key} className="each-session">
                        <Checkbox onChange={(e,data) =>  data.checked ? setSelectedSessions(selectedSessions => [...selectedSessions ,item.sessionId]) : setSelectedSessions(selectedSessions.filter(session => session !== item.sessionId))}/>
                        <div className="float-right right-width">
                            <div className="time"> {getDate(new Date(item.createTime)) + " " + getTime(new Date(item.createTime))} </div>
                            <div className="query"> {item.searchQuery} </div>
                            {
                                !item.troubleshootingData ? " " :
                                    <div className="task-details">
                                        <div className="task-name"> {item.troubleshootingData.info_type === "Examinations, tests & checks" ? "Work Instruction" : item.troubleshootingData.info_type} • {item.troubleshootingData.title} </div>
                                    </div>
                            }
                            <div className="info">
                                <Label circular className="collaborators" as='a' image={item.owner.avatar} />
                                {
                                    item.guests.map((collab_id, k) => {
                                        if (collab_id.avatar !== "") {
                                            return <Label key={k} circular className="collaborators" as='a' image={collab_id.avatar} />
                                        } else {
                                            return <Label key={k} circular className="collaborators" as='a' image={userDummy} />
                                        }
                                    })
                                }
                                {
                                    !item.task ? " " :
                                        <div className={getColor(item.task.status) + " marT10"}> {item.completeTime !== undefined ? 'Completed' : 'In Progress'} </div>
                                }
                            </div>
                        </div>
                    </div>
                )
            }).reverse()}
            <Menu borderless fixed='bottom' id="menu">
                <Menu.Item position='left'>
                    <Button className='secondarybtn' onClick={() => props.history.goBack()}>
                        Back
                    </Button>
                </Menu.Item>
                <Menu.Item position='right'>
                    <Button className='primarybtn' onClick={() => nextStep()}>
                        Continue
                    </Button>
                </Menu.Item>
            </Menu>
        </div>
    )
}

export default withRouter(connect(null, { saveSessionOptions, saveCompletedSessionOptions})(LexxReportSessions));